import { Card as MuiCard, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { varFade } from '@ysura/common';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS, HOMEPAGE_DIALOG } from '@/config/layout';
import { HomepageStep } from '@/pages/Homepage';

import { ContentWrapper } from './ContentWrapper';
import { HomepageGraphic } from './HomepageGraphic';

type HomepageStaticBlockProps = {
  homepageStep: HomepageStep;
  children: ReactNode;
};

export const HomepageStaticBlock = ({
  homepageStep,
  children,
}: HomepageStaticBlockProps) => {
  const variants =
    homepageStep === 'roomId'
      ? { ...varFade({ easeOut: 'easeOut' }).outLeftFullscreen }
      : { ...varFade({ easeIn: 'easeIn' }).inRightFullscreen };

  return (
    <AnimatePresence mode="wait">
      <motion.div>
        <Card key={homepageStep} {...variants}>
          <StyledStack>
            <HomepageGraphic homepageStep={homepageStep} />
            <ContentWrapper>{children}</ContentWrapper>
          </StyledStack>
        </Card>
      </motion.div>
    </AnimatePresence>
  );
};

const Card = styled(MuiCard)(({ theme }) => ({
  background: theme.palette.background.neutral,
  width: HOMEPAGE_DIALOG.WIDTH,
  height: HOMEPAGE_DIALOG.HEIGHT,

  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100%',
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  padding: theme.spacing(6),
  gap: theme.spacing(5),

  height: '100%',
  overflowY: 'auto',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    flexDirection: 'column',
    margin: theme.spacing(0),
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',

      width: '100%',

      gap: theme.spacing(3),
      padding: theme.spacing(3),
    },
}));
